/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'floppy2-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 2h-2v3h2z"/><path pid="1" d="M1.5 0A1.5 1.5 0 000 1.5v13A1.5 1.5 0 001.5 16h13a1.5 1.5 0 001.5-1.5V2.914a1.5 1.5 0 00-.44-1.06L14.147.439A1.5 1.5 0 0013.086 0zM4 6a1 1 0 01-1-1V1h10v4a1 1 0 01-1 1zM3 9h10a1 1 0 011 1v5H2v-5a1 1 0 011-1"/>',
    },
});
